export const rules = {
    T_sn: [{
        required: true,
        message: '设备编号',
        trigger: 'change'
    },],
    DeviceType: [{
        required: true,
        message: '设备类型',
        trigger: 'change'
    }],
    IsExpatriate: [{
        required: true,
        message: '是否外派',
        trigger: 'blur'
    }],
    Phone:[
        { required: true, message: '请输入手机号', trigger: 'change' },
        { min: 11, message: '请正确输入11位手机号', pattern:/^1[3456789]\d{9}$/, trigger: 'change' }
    ],

    ChildPid: [{
        required: true,
        message: '收货单位',
        trigger: 'change'
    }],
    ShippingUnit: [{
        required: true,
        message: '发货单位',
        trigger: 'change'
    }],

    T_time: [{
        required: true,
        message: '配送计划',
        trigger: 'change'
    }],
}

export const addressRules = {
    Phone:[
        { required: true, message: '请输入手机号', trigger: 'change' },
        { min: 11, message: '请正确输入11位手机号', pattern:/^1[3456789]\d{9}$/, trigger: 'change' }
    ],
    T_Address: [{
        required: true,
        message: '地址名称',
        trigger: 'change'  
    }],
    T_name: [{
        required: true,
        message: '公司名称',
        trigger: 'change'
    }],

}