export const initParam = {
    t_sn:'',
    t_orderid: '',
    startTime: '',
    endTime: '',
    page: 1,
    page_z: 10
}

/**
 * 新增或者编辑参数
 */
export const ruleForm = {
    T_orderid: '',//订单号
    WaybillNo: '',//运单号
    T_sn: '',//设备编号
    DeviceType: '',//设备类型
    IsExpatriate: 1,//是否外派 1 否 2 是
    Phone: '',//电话
    T_receiving: '',//收货单位
    ChildPid: '',//收货单位编号
    ShippingUnit: '',//发货单位
    T_time: '',//配送计划,需要切割为开始与结束时间
    CouriesId:null,//配送员
    EstimateStartTime: '',//配送计划开始时间
    EstimateEndTime: '',//配送计划结束时间
    imgArr: [],//随货同行单本地暂存数组
    PeerList: [],//随货同行单
    T_text: '',//详情
}


export const initForm = {
    page: 1,
    page_z: 8,
    T_Address: '',//地址名称
}

export const ruleForms = {
    Phone:'',//手机号
    T_Address:'',//地址
    T_name: '',//公司名称
}

//配送员
export const initFormcou = {
    page: 1,
    page_z: 8,
    T_name: '',//地址名称
}