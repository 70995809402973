// 传感器分类接口
import {
	axios
} from '../../utils/http.js'

const ClassApi = {
	OrderList: '/v3/Order/List',//订单管理列表
	OrderAdd: '/v3/Order/Add',//添加
	OrderNumber: '/v3/Order/CreateOrderNo',//获取订单号
	OrderDel: '/v3/Order/Del',//删除订单
    OrderEdit: '/v3/Order/Edit',//编辑订单
    OrderEdit: '/v3/Order/Edit',//编辑订单
    OrderPDF: '/v3/Order/PDF',//导出pdf
	DeviceList: '/v3/Device/List',//设备管理列表

	AddressList: '/v3/Addr/List',//地址列表
	AddressAdd: '/v3/Addr/Add',//地址添加
	AddressEdit: '/v3/Addr/Edit',//地址编辑
	AddressDel: '/v3/Addr/Del',//地址删除
	getdevData: '/v3/Order/GetDeviceData',//温湿度，地址

	getdevDataNot: '/v3/Order/GetDeviceDataNot',//温湿度，地址


    // 配送员
    CouriersList: '/v3/Couriers/List',//配送员列表
	CouriersAdd: '/v3/Couriers/Add',//地址添加
	CouriersEdit: '/v3/Couriers/Edit',//地址编辑
	CouriersDel: '/v3/Couriers/Del',//地址删除

     
}

export function CouriersList(data) {
    return axios({
        url: ClassApi.CouriersList,
        method: 'post',
        data:data
    })
}
export function CouriersAdd(data) {
    return axios({
        url: ClassApi.CouriersAdd,
        method: 'post',
        data:data
    })
}
export function CouriersEdit(data) {
    return axios({
        url: ClassApi.CouriersEdit,
        method: 'post',
        data:data
    })
}
export function CouriersDel(data) {
    return axios({
        url: ClassApi.CouriersDel,
        method: 'post',
        data:data
    })
}


export function testApi(data) {
    return axios({
        url: '/v3/Order/Test',
        method: 'post',
        data:data
    })
}
export function getdevDataNot(data) {
	return axios({
		url: ClassApi.getdevDataNot,
		method: 'post',
		data:data,
	})
}

export function OrderPDF(data) {
	return axios({
		url: ClassApi.OrderPDF,
		method: 'post',
		data:data,
	})
}
/**
 * 地址删除
 * @param {*} data 
 * @returns 
 */
export function getdevData(data) {
	return axios({
		url: ClassApi.getdevData,
		method: 'post',
		data:data,
	})
}
/**
 * 地址删除
 * @param {*} data 
 * @returns 
 */
export function AddressDel(data) {
	return axios({
		url: ClassApi.AddressDel,
		method: 'post',
		data:data,
	})
}
/**
 * 地址编辑
 * @param {*} data 
 * @returns 
 */
export function AddressEdit(data) {
	return axios({
		url: ClassApi.AddressEdit,
		method: 'post',
		data:data,
	})
}

/**
 * 地址添加
 * @param {*} data 
 * @returns 
 */
export function AddressAdd(data) {
	return axios({
		url: ClassApi.AddressAdd,
		method: 'post',
		data:data,
	})
}



/**
 * 地址列表
 * @param {*} data 
 * @returns 
 */
export function AddressList(data) {
	return axios({
		url: ClassApi.AddressList,
		method: 'post',
		data:data,
	})
}

//订单管理列表
export function OrderList(data) {
	return axios({
		url: ClassApi.OrderList,
		method: 'post',
		data:data,
	})
}
//编辑订单
export function OrderEdit(data) {
	return axios({
		url: ClassApi.OrderEdit,
		method: 'post',
		data:data
	})
}
//添加
export function OrderAdd(data) {
	return axios({
		url: ClassApi.OrderAdd,
		method: 'post',
		data:data
	})
}
/**
 * 删除订单
 * @param {*} data 
 * @returns 
 */
export function OrderDel(data) {
	return axios({
		url: ClassApi.OrderDel,
		method: 'post',
		data:data
	})
}
/**
 * 获取订单号
 * @param {*}  
 */
export function getNumber(data) {
    return axios({
		url: ClassApi.OrderNumber,
		method: 'post',
		data:data
	})
}

/***
 * 获取公司名称
 * @param {*} data
 */
export function getCompany(data) {
	return axios({
		url: "/v3/Company/Get",
		method: 'post',
		data:data
	})
}
/***
 * 获取公司列表
 * @param {*} data
 */
export function getcompanyTree(data) {
	return axios({
		url: "/v3/Company/Tree",
		method: 'post',
		data:data
	})
}

//设备管理列表
export function DeviceList(data) {
	return axios({
		url: ClassApi.DeviceList,
		method: 'post',
		data:data
	})
}



